import { graphql, navigate } from 'gatsby';

import DynamicZone from '../../components/dynamic-zone/dynamic-zone';
import FloatingButton from '../../components/floating-button/floating-button';
import Layout from '../../components/layout';
import React from 'react';

const PressRoomPage = (props) => {
    const components = props.data.cms.pressRoomPageTemplate?.components || null;
    const mediaContact = props.data.cms.mediaContactTemplate;

    if (!components) {
        if (typeof window !== `undefined`) {
            navigate(`${props.pageContext.locale === `pl` ? `/pl` : ``}/404`);
        }
        return null;
    }

    return (
        <Layout location={props.location} locale={props.pageContext.locale}>
            <FloatingButton />
            <DynamicZone components={components} mediaContact={mediaContact} />
        </Layout>
    )
}

export default PressRoomPage;

export const query = graphql`
query ($locale: String!){
  cms {
    mediaContactTemplate(locale: $locale) {
      newMediaContact {
        mediaContactEmails
        mediaContactFollowUsLabel
        mediaContactMediaKitLabel
        mediaContactTitle
        mediaContactDownloadables {
          mediaContactDownloadableFile {
            url
            name
          }
          mediaContactDownloadableLabel
        }
        mediaContactSocialsLinks {
          mediaContactSocialLink
          mediaContactSocialLinkIcon {
            url
          }
        }
      }
    }
    pressRoomPageTemplate(locale: $locale) {
      components {
        ... on STRAPI_ComponentComponentsHeader {
          __typename
          backgroundTopOffset
          backgroundImageHeight
          backgroundImage {
            url
          }
        }
        ... on STRAPI_ComponentPressReleasesPressReleases {
          __typename
          sectionTitle {
            ytLink
            title
            tailwindClasses
            style
            description
          }
          press_room_posts(sort: "id:desc, sortDate:desc, published_at:desc") {
            author {
              title
              nameAndSurname
              image {
                url
              }
              bio
            }
            mainImage {
              url
            }
            readLength
            pressRoomPageText
            slug
            title
            date
          }
      }
      ... on STRAPI_ComponentSeoSeo {
          __typename
          seoDescription
          seoLink
          seoTitle
          seoImages {
            url
          }
        }
    }
  }
}
}
`;